import { redirect, type MetaFunction } from '@remix-run/node'

import { safeRedirect } from 'remix-utils/safe-redirect'
import { GeneralErrorBoundary } from '#app/components/error-boundary.tsx'
import { Icon } from '#app/components/ui/icon.tsx'

import { sessionKey } from '#app/utils/auth.server.ts'
import {
	ProviderConnectionForm,
	providerNames,
} from '#app/utils/connections.tsx'

import { combineResponseInits } from '#app/utils/misc.tsx'
import { authSessionStorage } from '#app/utils/session.server.ts'
import { useT } from '#app/utils/usei18n.tsx'

export async function handleNewSession(
	{
		request,
		session,
		redirectTo,
		remember,
	}: {
		request: Request
		session: { userId: string; id: string; expirationDate: Date }
		redirectTo?: string
		remember: boolean
	},
	responseInit?: ResponseInit,
) {
	const authSession = await authSessionStorage.getSession(
		request.headers.get('cookie'),
	)
	authSession.set(sessionKey, session.id)

	return redirect(
		safeRedirect(redirectTo),
		combineResponseInits(
			{
				headers: {
					'set-cookie': await authSessionStorage.commitSession(authSession, {
						expires: remember ? session.expirationDate : undefined,
					}),
				},
			},
			responseInit,
		),
	)
}

export default function LoginPage() {
	const t = useT('translation', 'login')

	return (
		<>
			<div className="container relative  z-50 grid h-screen grid-cols-1 flex-col items-stretch justify-center bg-white px-0 md:max-w-none md:grid-cols-2 md:items-center dark:bg-elysium">
				<div className="relative flex w-full flex-col items-center justify-center bg-muted md:h-full md:p-8 lg:p-10">
					<div className="absolute inset-0 bg-light-biege dark:bg-midnight" />
					<div className="relative z-20 flex flex-col items-center text-lg font-medium">
						<Icon
							name="ai-assistant"
							className="mb-[51px] h-auto w-full max-w-[401px]"
						/>
						<h2 className="mb-4 text-2xl font-light">{t('imageTitle')}</h2>
						<p className="text-sm font-light">{t('imageSubtitle')}</p>
					</div>
				</div>
				<div className="flex md:h-full lg:p-8">
					<div className="mx-auto flex w-[350px] flex-col justify-center space-y-6 lg:w-full">
						<div className="flex flex-col space-y-2 text-center">
							<Icon name="aldowise" className="mb-[54px] mt-8 w-[124px]" />
							<h1 className="pb-[24px] text-2xl font-light lg:pb-[52px]">
								{t('title')}
							</h1>
						</div>
						<ul className="mt-5 flex flex-col gap-5 border-border py-3">
							{providerNames.map(providerName => (
								<li key={providerName}>
									<ProviderConnectionForm
										type={t('type')}
										providerName={providerName}
									/>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</>
	)
}

export const meta: MetaFunction = () => {
	return [
		{ title: 'AldoWise - Login' },
		{ name: 'description', content: 'Welcome to Aldo AI App' },
	]
}

export function ErrorBoundary() {
	return <GeneralErrorBoundary />
}
